<template>
  <v-row>
    <loading :active.sync="loading"></loading>
    <v-col>
      <span
        style="
          font-family: 'IBM Plex Sans Thai';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
        "
      >
        ส่งบทความวิชาการระดับชาติ
      </span>
    </v-col>
    <v-card class="pa-6" width="100%">
      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-row>
          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อ (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="name_th"
              placeholder="ชื่อ (TH)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">ชื่อ (EN)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <v-text-field
              hide-details
              v-model="name_en"
              placeholder="ชื่อ (EN)"
              dense
              outlined
              :rules="rules.required"
              disabled
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">เนื้อหา (TH)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <quill-editor
              v-model="submission_details_th"
              :content="content"
              :options="editorOption"
              @change="onEditorChange($event)"
              disabled
            />
          </v-col>

          <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">เนื้อหา (EN)</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <quill-editor
              v-model="submission_details_en"
              :content="content1"
              :options="editorOption"
              @change="onEditorChange1($event)"
              disabled
            />
          </v-col>

          <!-- <v-col cols="12" md="3" sm="3"
            ><span class="pt-2">Upload File</span>
            <span style="color: red">*</span></v-col
          >
          <v-col cols="12" md="7" sm="7">
            <form action="/action_page.php">
              <input type="file" id="file" name="filename" ref="q_1_1" />
            </form>
          </v-col> -->

          <!-- <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-card outlined>
                <v-row no-gutters align="center">
                  <v-col
                    cols="12"
                    md="11"
                    sm="11"
                    xs="11"
                    class="ma-4"
                    id="step-3"
                  >
                    <span>เลือกรูปภาพหน้าปก</span>
                    <span style="color: red">*</span>
                    <br />
                    <span style="color: red">อัพโหลดรูปภาพขนาด 500x250px</span>
                    <br />
                    <v-img :src="showImage" max-width="auto"></v-img>

                    <v-col cols="12" md="12" class="text-right">
                      <input
                        type="file"
                        accept="image/*"
                        id="file"
                        ref="q_1_1"
                        v-on:change="handleFileUpload('q_1_1')"
                      />
                    </v-col>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row> -->
        </v-row>

        <v-row align="center" justify="end">
          <v-btn @click="cancel()" class="mr-4">กลับ</v-btn>
          <!-- <v-btn @click="submit('q_1_1')" dark color="#2AB3A3">บันทึก</v-btn> -->
        </v-row>
      </v-form>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
import { VueEditor } from "vue2-editor";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
    VueEditor,
  },
  data() {
    return {
      name_en: "",
      name_th: "",
      submission_details_th: "",
      submission_details_en: "",
      type_th: 1,
      type_en: "",

      content: "",
      content1: "",

      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      pic1: "",
      picture_1: "",
      pic1URL: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
        pic: [(v) => !!v || "กรุณาอัพโหลดรูป"],
      },
      checkValidate: true,
      lazy: false,
      user: "",
      linkUrl: "",
      productId: 0,
      items: [],
      category: "",
      itemsCategory: [],
      type: "CONTENT",
      category: "",
      desc: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      img: "",
      loading: false,
      content_url: "",
      loading: false,
      file: [],
      video_url: "",
      itemTypes: [
        {
          name: "CONTENT",
          val: "CONTENT",
        },
        {
          name: "VIDEO",
          val: "VIDEO",
        },
      ],
      itemCategory: [
        {
          name: "แนะนำ",
          val: "SUGGEST",
        },
        {
          name: "ครอบครัว",
          val: "HOME2",
        },
        {
          name: "งานวิจัย",
          val: "RESEARCH",
        },
      ],
      levelItems: [],
      FacultyItems: [],

      dataAcademicConferences1: [],
    };
  },
  watch: {
    productId(val) {
      if (val) {
        console.log("productId", val);
      }
    },
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.dataAcademicConferences1 = JSON.parse(
      Decode.decode(localStorage.getItem("dataAcademicConferences1"))
    );
    this.getOne();
  },
  methods: {
    async getOne() {
      this.loading = true;
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("gscmAdmin"))
      );
      console.log("this.userData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/academicConferences/${this.dataAcademicConferences1.id}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbannerpe", response.data.data);
      // this.name_th = response.data.data.name_th;
      this.name_th = response.data.data.name_th;
      this.name_en = response.data.data.name_en;
      this.submission_details_en = response.data.data.submission_details_en;
      this.submission_details_th = response.data.data.submission_details_th;
      this.type_th = response.data.data.type_th;
      this.loading = false;
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      console.log("55");
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.picture_1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageAcademicConferences1");
    },
    async handleFileUpload(questionNo) {
      // this.data[questionNo] = this.$refs[questionNo].files[0];
      // console.log("file", this.data[questionNo]);
    },

    async submitfileMain(questionNo) {
      if (this.$refs[questionNo].files[0]) {
        let formData = new FormData();
        formData.append("files", this.$refs[questionNo].files[0]);
        formData.append("filename", this.$refs[questionNo].files[0].name);
        formData.append("type", "uploadfile");
        // formData.append("userId", this.userId);
        console.log("formData", formData);
        const auth = {
          headers: {
            "Content-Type": "multipart/form-data",
            // Authorization: `Bearer` + " " + this.checkuser.token,
          },
        };
        console.log("auth", auth);
        const response = await this.axios.post(
          `https://gscm-service.yuzudigital.com/files/formData`,
          formData,
          auth
        );
        if (questionNo == "q_1_1") {
          this.cover_img_url = response.data.data.path;
          this.showImage = response.data.data.path;
        }

        console.log("SUCCESS!!");
        console.log("response", response);
      }
    },
    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        //   await this.submitfileMain(pic);
        //   console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name_en: this.name_en,
          name_th: this.name_th,
          submission_details_th: this.submission_details_th,
          submission_details_en: this.submission_details_en,
          type_th: this.type_th,
        };
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/academicConferences`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `ส่งบทความวิชาการระดับชาติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$router.push("ManageAcademicConferences1");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
